var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_vm._t("default"),(_vm.askOldPassword)?_c('ValidationProvider',{attrs:{"rules":"required","vid":"oldPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('password_change.label.old_password'),"message":errors,"type":{ 'is-danger': errors[0], '': valid },"custom-class":_vm.labelClass}},[_c('b-input',{attrs:{"type":"password","icon":"lock","placeholder":"Password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('password_change.label.new_password'),"message":errors,"type":{ 'is-danger': errors[0], '': valid },"custom-class":_vm.labelClass}},[_c('b-input',{attrs:{"type":"password","icon":"lock","placeholder":"Password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|confirmed:password","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('password_change.label.confirm_new_password'),"message":errors,"type":{ 'is-danger': errors[0], '': valid },"custom-class":_vm.labelClass}},[_c('b-input',{attrs:{"type":"password","icon":"lock","placeholder":"Password","lazy":""},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1)]}}],null,true)}),_c('b-button',{staticClass:"is-primary mt-3",attrs:{"native-type":"submit"}},[_vm._v(_vm._s(_vm.$t("password_change.buttons.change")))])],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }